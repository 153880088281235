<template>
  <div class="row discount mt-2">
    <b-col lg="12">
      <div class="card">
        <div class="card-body">
          <div class="form-group row justify-content-center">
            <div class="col-sm-2">
              <b-form-select
                id="currency"
                class="mb-2"
                v-model="group"
                :options="groupOptions"
                required
                @change="onChangeGroup"
              ></b-form-select>
            </div>
            <div class="col-sm-6">
              <input
                type="text"
                class="form-control"
                placeholder="type email user admin..."
                @input="debounceSearch"
              />
              <span v-if="typing"> <em> searching.. </em> </span>
            </div>
            <div class="col-md-auto">
              <router-link
                :to="{ path: `/user-admin/create` }"
                class="btn btn-block btn-primary"
              >
                <i class="fa fa-plus"> </i>
                Add User Admin
              </router-link>
            </div>
          </div>
          <b-row class="justify-content-end">
            <div class="col-sm-auto pb-2">
              Count :
              <strong class="pl-2">
                {{ formatCount(totalRows) }}
              </strong>
            </div>
          </b-row>
          <b-table
            sticky-header="500px"
            responsive="sm"
            :items="items"
            :fields="fields"
            :busy="isLoading"
            :isLoading="isLoading"
            show-empty
            striped
            select-mode="single"
            hover
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}.
            </template>
            <template #cell(action)="row">
              <div class="btn-group" role="group" aria-label="Basic example">
                <router-link
                  :to="{ path: '/user-admin/edit/' + row.item.id }"
                  class="link btn btn-info"
                >
                  <i class="fa fa-pencil"></i>
                </router-link>
              </div>
            </template>
            <template #cell(display)="row">
              <div class="btn-group" role="group" aria-label="Basic example">
                <router-link
                  :to="{ path: '/user-admin/display/' + row.item.id }"
                  class="link btn btn-warning"
                >
                  <i class="fa fa-eye"></i>
                </router-link>
              </div>
            </template>
            <template #cell(is_active)="row">
              <span v-if="row.item.is_active" class="badge badge-primary">
                Active
              </span>
              <span v-else class="badge badge-danger">Inactive</span>
            </template>

            <template #cell(email)="row">
              <div style="width: 250px">
                {{ row.item.email ?? '-' }}
              </div>
            </template>

            <template #cell(role)="row">
              <div class="font-weight-bold" style="width: 200px">
                {{ row.item.role_name }}
              </div>
            </template>

            <template #cell(group)="row">
              <div v-if="row.item.group == 'ebook'">
                <span class="badge badge-pill badge-info">{{
                  row.item.group
                }}</span>
              </div>
              <div v-if="row.item.group == 'publisher'">
                <span class="badge badge-pill badge-warning">{{
                  row.item.group
                }}</span>
              </div>
            </template>

            <template #cell(vendor)="row">
              <div style="width: 250px">
                {{ row.item.vendor_name ?? '-' }}
              </div>
            </template>

            <template #cell(createdAt)="row">
              <div style="width: 250px">
                {{ row.item.createdAt ? formatDate(row.item.createdAt) : '-' }}
              </div>
            </template>

            <template #cell(updatedAt)="row">
              <div style="width: 250px">
                {{ row.item.updatedAt ? formatDate(row.item.updatedAt) : '-' }}
              </div>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading...
              </div>
            </template>
          </b-table>
          <b-row class="justify-content-start">
            <b-col lg="3">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="my-table"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </b-col>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'ListUserAdmin',
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      filter: {},
      isLoadingSearch: false,
      items_search: [],
      fields: [
        { key: 'No', label: 'No.' },
        { key: 'email', label: 'Email' },
        { key: 'vendor', label: 'Vendor' },
        { key: 'role', label: 'Role' },
        { key: 'group', label: 'Group' },
        { key: 'is_active', label: 'Status' },
        {
          key: 'createdAt',
          label: 'Created',
        },
        {
          key: 'updatedAt',
          label: 'Updated',
        },
        { key: 'display', label: '' },
        { key: 'action', label: '' },
      ],
      message: null,
      typing: null,
      group: '',
      groupOptions: [
        { value: '', text: 'All Group' },
        { value: 'ebook', text: 'Ebook' },
        { value: 'publisher', text: 'Publisher' },
      ],
    };
  },
  computed: {
    // ...mapGetters('admin', { items: 'admin' }),
    ...mapState({
      items: (state) => state.admin.items,
      isLoading: (state) => state.admin.isLoading,
      isError: (state) => state.admin.isError,
      totalRows: (state) => state.admin.totalRows,
    }),
  },
  mounted() {
    this.actionFetchAll();
  },
  watch: {
    currentPage: function() {
      this.actionFetchAll();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'User Admin | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  methods: {
    ...mapActions('admin', ['fetchAdmin', 'search']),
    actionFetchAll() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      if (this.message) payload.email = this.message;
      if (this.group) payload.group = this.group;

      this.fetchAdmin(payload);
    },
    debounceSearch(event) {
      this.message = null;
      this.typing = 'You are typing';
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = null;
        this.message = event.target.value;
        this.fetchAdmin({
          email: event.target.value,
          page: 1,
          limit: this.perPage,
          group: this.group,
        });
      }, 600);
    },

    onChangeGroup() {
      let payload = {
        email: this.message,
        group: this.group,
        page: this.currentPage,
        limit: this.perPage,
      };
      this.fetchAdmin(payload);
    },

    formatCount(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },

    formatDate(tgl) {
      return new Date(tgl).toString();
    },
  },
};
</script>
